export type FormSize = 'sizeSmall' | 'sizeMedium' | 'sizeLarge';

export enum ComponentSize {
  Base = 'base',
  Large = 'large',
}

export enum ComponentDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum PriceType {
  VC = 'vc',
  XDO = 'xdo',
}

export type TranslationParams = {
  key: string;
  params?: {};
};

export type TranslationFn = (
  key: TranslationParams['key'],
  params?: TranslationParams['params']
) => string | any[];

export type DefaultPageProps = {
  locale?: string;
  stateCode?: string;
  isMobile?: boolean;
  isTablet?: boolean;
  countryCode?: string;
  translations?: Record<string, string>;
};

export enum VFMCategory {
  Unknown = 0,
  BetterValueElsewhere = 1,
  Fair = 2,
  Good = 3,
  Better = 4,
  Great = 5,
  Amazing = 6,
}
